<template>
  <VCard title-class="bg-theme-5">
    <template #title>
      <div class="flex items-center">
        <VImage
          class="mr-2"
          :src="avatar"
          :name="name"
          :width="12"
          :height="12"
        />

        <div>
          <div class="text-base font-medium">
            {{ name }}
          </div>

          <div class="label text-sm font-light">
            {{ username }}
          </div>
        </div>
      </div>
    </template>

    <template #title-actions>
      <VChip
        :text="$t(`app.${request?.status}`)"
        :class="getStatusColor(request?.status)"
      />
    </template>

    <template #content>
      <div class="mb-4">
        {{ getText(request?.attestation?.texts, "name") }}
      </div>

      <div class="grid grid-cols-2 gap-4">
        <VLine class="col-span-2" :label="$t('app.trainings')">
          <template #value>
            <div class="cursor-pointer" @click="onClickRedirectToTraining">
              {{ getText(training?.texts, "name") }}
            </div>
          </template>
        </VLine>

        <VLine class="col-span-2" :label="$t('app.files')">
          <template #value>
            <FileItems :files="request?.files" />
          </template>
        </VLine>

        <VLine
          class="col-span-2"
          :label="$t('app.requested_on')"
          :value="formatDate({ date: request.requested_on })"
        />

        <VLine :label="$t('app.submitted_by')" :value="submittedBy" />

        <VLine
          :label="$t('app.submitted_on')"
          :value="formatDate({ date: request.submitted_on })"
        />
      </div>
    </template>
  </VCard>
</template>

<script>
import { computed } from "vue";
import { useRouter } from "vue-router";
// Composables
import useDisplay from "@/composables/useDisplay";
import useTexts from "@/composables/useTexts";
import useColor from "@/composables/useColor";
// Components
import VCard from "@/components/VCard";
import VLine from "@/components/VLine";
import VChip from "@/components/VChip";
import VImage from "@/components/VImage";
import FileItems from "@/components/FileItems";

export default {
  components: {
    VCard,
    VChip,
    VImage,
    VLine,
    FileItems
  },
  props: {
    request: {
      type: Object,
      default: () => ({})
    }
  },
  setup(props) {
    // Misc
    const router = useRouter();

    // Composables
    const { getText } = useTexts();
    const { getStatusColor } = useColor();
    const { formatDate, getAvatarURL } = useDisplay();

    // Computed
    // eslint-disable-next-line
    const training = computed(() => props.request?.registration?.training || {});
    const learner = computed(() => props.request?.registration?.learner || {});
    // eslint-disable-next-line
    const avatar = computed(() => getAvatarURL(learner.value?.avatar?.view_path));

    const name = computed(() => {
      const { firstname, lastname } = learner.value;

      return firstname && lastname ? `${firstname} ${lastname}` : "";
    });

    const username = computed(() => {
      const { username } = learner.value;

      return username ? `@${username}` : "";
    });

    const submittedBy = computed(() => {
      const { firstname, lastname } = props?.request?.submitted_by ?? {};

      return firstname && lastname ? `${firstname} ${lastname}` : "";
    });

    // Methods
    const onClickRedirectToTraining = () => {
      router.push({
        name: "teaching-trainings-update",
        params: {
          id: training.value.id
        }
      });
    };

    return {
      submittedBy,
      onClickRedirectToTraining,
      name,
      username,
      training,
      avatar,
      // useDisplay
      formatDate,
      // useTexts
      getText,
      // useColor
      getStatusColor
    };
  }
};
</script>
