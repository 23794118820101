<template>
  <div>
    <VTitle :title="$t('app.submissions')" class="section-title" />

    <SubmissionDetails :request="data" />

    <VList
      :rows="data.otherSubmissions"
      :headers="headers"
      :is-loading="isLoading"
      :title="$t('app.all_submissions')"
    >
      <!-- eslint-disable-next-line -->
      <template #item.submitted_on="{ item }">
        {{ formatDate({ date: item.submitted_on, displayTime: true }) }}
      </template>

      <!-- eslint-disable-next-line -->
      <template #item.submitted_by="{ item }">
        <div>
          {{ item.submitted_by?.firstname }} {{ item.submitted_by?.lastname }}

          <div class="font-light text-xs">
            {{
              item.submitted_by?.username
                ? `@${item.submitted_by?.username}`
                : ""
            }}
          </div>
        </div>
      </template>

      <!-- eslint-disable-next-line -->
      <template #item.files="{ item }">
        <FileItems :files="item.files" />
      </template>

      <!-- eslint-disable-next-line -->
      <template #item.comments="{ item }">
        <div class="break-all w-56 truncate">
          {{ item.instructor_comments }}
        </div>
      </template>

      <!-- eslint-disable-next-line -->
      <template #item.status="{ item }">
        <div class="flex justify-center">
          <VChip
            :text="$t(`app.${item.status}`)"
            :class="getStatusColor(item.status)"
          />
        </div>
      </template>
    </VList>
  </div>
</template>

<script>
import { onMounted, computed, ref } from "vue";
import { useI18n } from "vue-i18n";
// Composables
import useRequest from "@/composables/useRequest";
import useColor from "@/composables/useColor";
import useDisplay from "@/composables/useDisplay";
import useTexts from "@/composables/useTexts";
// Components
import VTitle from "@/components/VTitle";
import VList from "@/components/tables/VList";
import VChip from "@/components/VChip";
import FileItems from "@/components/FileItems";
import SubmissionDetails from "./SubmissionDetails";

export default {
  components: {
    VList,
    VTitle,
    FileItems,
    VChip,
    SubmissionDetails
  },
  props: {
    id: {
      type: [Number, String],
      required: true
    },
    submissionId: {
      type: [Number, String],
      required: true
    }
  },
  setup(props) {
    // Misc
    const { t } = useI18n();

    // Composables
    const { isRequesting: isLoading, request } = useRequest();
    const { getStatusColor } = useColor();
    const { formatDate } = useDisplay();
    const { getText } = useTexts();

    // Data
    const data = ref({});

    // Constants
    const headers = [
      {
        text: t("app.submitted_on"),
        value: "submitted_on"
      },
      {
        text: t("app.submitted_by"),
        value: "submitted_by"
      },
      {
        text: t("app.files"),
        value: "files"
      },
      {
        text: t("app.comments"),
        value: "comments"
      },
      {
        text: t("app.status"),
        value: "status"
      }
    ];

    // Computed
    const documentTitle = computed(() => {
      const pretitle = t("app.submission_details");
      // eslint-disable-next-line
      const title = `${t("app.submissions", 2)} - ${t("app.attestations", 2)} - ${t("app.teaching")}`;

      const name = getText(props.attestation?.texts, "name");
      if (name) {
        return `${pretitle} - ${name} - ${title}`;
      }

      return `${pretitle} - ${title}`;
    });

    // Methods
    const getData = async () => {
      const response = await request({
        endpoint: "teaching.attestations.submission",
        pathParams: {
          id: props.submissionId
        },
        showToaster: false
      });

      data.value = response?.payload?.data ?? {};
    };

    // Lifecycle Hooks
    onMounted(async () => {
      await getData();
    });

    return {
      documentTitle,
      data,
      headers,
      // useDisplay
      formatDate,
      // useColor
      getStatusColor,
      // useRequest
      isLoading
    };
  }
};
</script>
